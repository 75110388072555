import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Walton = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.open("https://waltonbd.com/air-conditioner/Split-ac", "_blank");
    navigate("/");
  }, [navigate]);
  return (
    <div className="container-fluid p-0">
      {/* <div className="text-center mt-4">
        <p className="display-6 text-muted">
          বৈশাখী অফার চলছে। সকল মডেলের ওয়ালটন এসি তে ১২% ছাড়
        </p>
      </div>
      <div className="walton-card position-relative">
        <div className="position-absolute bottom-0 end-0 shadow mb-4">
          <a
            target="_blank"
            href="https://waltonbd.com/air-conditioner/Split-ac"
            rel="noopener noreferrer"
          >
            <button type="button" className="btn btn-danger px-5">
              See more details
            </button>
          </a>
        </div>
      </div> */}
    </div>
  );
};

export default Walton;
