import React from "react";
import { FaEnvelope, FaMobileAlt } from "react-icons/fa";

const TopNav = () => {
  return (
    <div className="container-fluid bg-dark text-light">
      <div className="row justify-content-evenly text-center">
        <div className="col-sm-4 order-1 order-sm-0">
          <p className="mt-0 mt-sm-2">
            <span className="me-2">
              <FaEnvelope />
            </span>
            <a className="text-light" href="mailto:rac3dsp@gmail.com">
              rac3dsp@gmail.com
            </a>
          </p>
        </div>
        <div className="col-sm-4 order-0 order-sm-1">
          <p className="mt-0 mt-sm-2">
            <span className="me-2">
              <FaMobileAlt />
            </span>
            <a className="text-light" href="tel:01712372441">
              01712372441
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
