import firebaseInitialize from "../Components/Authentication/Firebase/firebaseInitialize";
import {
  getAuth,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { useEffect, useState } from "react";

firebaseInitialize();

const useFirebase = () => {
  const [loggedInUser, setLoggedInUser] = useState({});
  const [loading, setLoading] = useState(true);

  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedInUser(user);
      } else {
        setLoggedInUser({});
      }
      setLoading(false);
    });
    return () => unSubscribe;
  }, [auth]);

  const signInWithGoogle = (navigate) => {
    setLoading(true);
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const user = result.user;
        setLoggedInUser(user);
        navigate("/dashboard");
      })
      .catch((error) => {
        /* alert(error.message); */
      })
      .finally(() => setLoading(false));
  };

  const handleSignOut = () => {
    setLoading(true);
    signOut(auth)
      .then(() => {
        setLoggedInUser({});
      })
      .catch((error) => {
        // An error happened.
      })
      .finally(() => setLoading(false));
  };

  return {
    loggedInUser,
    loading,
    signInWithGoogle,
    handleSignOut,
  };
};

export default useFirebase;
