import React from "react";

const FallbackLoading = () => {
  return (
    <div className="border-top card min-vh-100">
      <div className="text-center mt-3">
        <div className="spinner-grow text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default FallbackLoading;
