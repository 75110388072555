import React from "react";

const Student = () => {
  return (
    <div className="container py-4">
      <div className="row">
        <p className="text-center text-muted display-6">Please drop your cv</p>
        <p className="text-center fw-bold">rac3dsp@gmail.com</p>
      </div>
    </div>
  );
};

export default Student;
