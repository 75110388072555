import React from "react";
import { Link } from "react-router-dom";
import {
  FaAngleRight,
  FaMobileAlt,
  FaEnvelope,
  FaMapMarkerAlt,
  FaFacebookSquare,
  FaTwitterSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import logo from "../../Images/logo.jpeg";

const Footer = () => {
  return (
    <footer>
      <div className="container-fluid bg-dark text-light">
        <div className="row justify-content-center pt-4">
          <div className="col-md-4 col-lg-3 pt-4 p-5 p-sm-2">
            <h3 className="mb-3">Contact Us</h3>
            <div className="text-muted">
              <p className="m-0">
                <span>
                  <FaMobileAlt />
                </span>{" "}
                +8801712372441
              </p>
              <p className="m-0">
                <span>
                  <FaMobileAlt />
                </span>{" "}
                +8801911609161
              </p>
              <p>
                <span>
                  <FaEnvelope />
                </span>{" "}
                rac3dsp@gmail.com
              </p>
            </div>
            <h5>Corporate Address:- </h5>
            <div className="text-muted">
              <p>
                <span>
                  <FaMapMarkerAlt />
                </span>{" "}
                Moghorkhal, Baipas bason sarok, Gazipur
              </p>
            </div>
          </div>
          <div className="col-md-4 col-lg-2 pt-4 p-5 p-sm-2">
            <h3 className="mb-4">Others Pages</h3>
            <ul className="list-unstyled">
              <li className="mb-2">
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                  <FaAngleRight /> Help
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                  <FaAngleRight /> Terms of Service
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                  <FaAngleRight /> Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 col-lg-5 pt-4 align-self-center p-5 p-sm-2">
            <div className="d-sm-flex">
              <img
                src={logo}
                alt="logo"
                className="img-fluid rounded-circle mb-4 mb-sm-0 border"
                width="150px"
              />
              <div className="ms-3">
                <h6>We provide the best service and quality service.</h6>
                <div className="mt-4 text-center">
                  <h4>Social Networking</h4>
                  <a
                    href="https://www.facebook.com/Nazmulrac3d/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookSquare size="30px" />
                    <span className="text-light ms-2"></span>
                  </a>
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    <FaTwitterSquare size="30px" />
                    <span className="text-light ms-2"></span>
                  </a>
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    <FaYoutubeSquare size="30px" />
                    <span className="text-light ms-2"></span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="text-center d-sm-flex justify-content-sm-around">
            <p className="m-0">
              <small>
                Copyright &copy; {new Date().getFullYear()}{" "}
                <Link
                  to="https://rac3dspbd.com/"
                  style={{ textDecoration: "none" }}
                >
                  <span className="text-light fst-italic fw-bold">
                    {" "}
                    rac3dspbd.com{" "}
                  </span>
                </Link>
                || All Rights Reserved.
              </small>
            </p>
            <p className="text-muted m-0">
              Developed By
              <a
                href="https://aynalhossain.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                AynalHossain
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
