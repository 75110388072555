import React, { Suspense, lazy } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";

import TopNav from "./Components/Layout/TopNav";
import Header from "./Components/Layout/Header";
import Footer from "./Components/Layout/Footer";

import AuthProvider from "./Components/Authentication/Context/AuthProvider";
import RequireAuth from "./Components/Authentication/RequireAuth";
import FallbackLoading from "./Components/Layout/FallbackLoading";
import Walton from "./Components/Views/Walton";
import Student from "./Components/Views/Student";

const Home = lazy(() => import("./Components/Views/Home"));
const ServiceGallery = lazy(() => import("./Components/Views/ServiceGallery"));
const AboutUs = lazy(() => import("./Components/Views/AboutUs"));
const Contact = lazy(() => import("./Components/Views/Contact"));
const Login = lazy(() => import("./Components/Views/Login"));
const Dashboard = lazy(() =>
  import("./Components/Layout/Main/Dashboard/Dashboard")
);
const PanelHome = lazy(() =>
  import("./Components/Layout/Main/Admin/ActiveDashboard/PanelHome")
);
const ServiceOrdersList = lazy(() =>
  import("./Components/Layout/Main/Admin/Containers/ServiceOrdersList")
);
const ServiceOrdersEdit = lazy(() =>
  import("./Components/Layout/Main/Admin/Containers/ServiceOrdersEdit")
);
const AddGallery = lazy(() =>
  import("./Components/Layout/Main/Admin/Containers/AddGallery")
);
const UsersList = lazy(() =>
  import("./Components/Layout/Main/Admin/Containers/UsersList")
);
const Orders = lazy(() => import("./Components/Layout/Main/Dashboard/Orders"));
const ContactMessageList = lazy(() =>
  import("./Components/Layout/Main/Admin/Containers/ContactMessageList")
);
const MyContact = lazy(() =>
  import("./Components/Layout/Main/Dashboard/MyContact")
);
const NotFound = lazy(() => import("./Components/Layout/NotFound"));

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <TopNav />
        <Header />
        <Suspense fallback={<FallbackLoading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/walton" element={<Walton />} />
            <Route path="/ips" element={<ServiceGallery />} />
            <Route path="/student" element={<Student />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            >
              <Route path="/dashboard" element={<PanelHome />} />
              <Route
                path="/dashboard/service-request-list"
                element={<ServiceOrdersList />}
              />
              <Route
                path="/dashboard/service-request-edit/:id"
                element={<ServiceOrdersEdit />}
              />
              <Route path="/dashboard/gallery" element={<AddGallery />} />
              <Route path="/dashboard/users" element={<UsersList />} />
              <Route
                path="/dashboard/message"
                element={<ContactMessageList />}
              />
              <Route path="/dashboard/my-orders" element={<Orders />} />
              <Route path="/dashboard/my-message" element={<MyContact />} />
            </Route>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AuthProvider>
  );
}

export default App;
