import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../Hook/useAuth";

const Header = () => {
  const { loggedInUser, handleSignOut } = useAuth();

  let navigate = useNavigate();

  const handleControlPanel = () => {
    navigate("login");
  };
  return (
    <header className="shadow border-bottom border-3">
      <nav className="navbar navbar-expand-md navbar-dark bg-primary">
        <div className="container">
          <Link className="navbar-brand" to="/">
            RAC3DServicePoint
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 text-center">
              <li className="nav-item mx-3">
                <NavLink className="nav-link" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink className="nav-link position-relative" to="/walton">
                  Walton
                </NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink className="nav-link" to="/ips">
                  IPS
                </NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink className="nav-link" to="/student">
                  Student
                </NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink className="nav-link" to="/about-us">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item mx-3">
                <NavLink className="nav-link" to="/contact">
                  Contact
                </NavLink>
              </li>
              {loggedInUser?.email && (
                <li className="nav-item mx-3">
                  <NavLink className="nav-link" to="/dashboard">
                    Dashboard
                  </NavLink>
                </li>
              )}
            </ul>
            {loggedInUser?.email ? (
              <button
                onClick={handleSignOut}
                type="button"
                className="btn btn-danger"
              >
                Sign out
              </button>
            ) : (
              <button
                onClick={handleControlPanel}
                type="button"
                className="btn btn-light"
              >
                Access Control
              </button>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
